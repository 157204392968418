<template>
	<b-modal
		active
		class="modal-acao-experiencia"
		:can-cancel="['escape', 'x']"
		@close="$emit('close')"
	>
		<div class="acao-experiencia-modal-container">
			<Icon
				file="experiencias/interesse_confirmado"
				size="200:200"
			/>

			<strong class="titulo">
				Você demonstrou interesse {{ InteresseNomeEnum[interesse].toLowerCase() }} na experiência:
			</strong>

			<strong class="experiencia">
				{{ experiencia.nome }}
			</strong>

			<span class="info-extra">
				Aguarde a confirmação de sua participação.<br>
				Caso seja selecionado, você será notificado.
			</span>

			<b-button
				type="is-primary"
				rounded
				@click="$emit('close')"
			>
				Fechar
			</b-button>
		</div>
	</b-modal>
</template>

<script>
import Icon from '../Icon.vue';
import { InteresseNomeEnum } from '@enum/experiencias/interesse';

export default {
	name: 'AcaoExperienciaModal',

	components: {
		Icon
	},

	props: {
		interesse: {
			required: true,
			type: Number,
		},

		experiencia: {
			required: true,
			type: Object,
		},
	},

	data() {
		return {
			InteresseNomeEnum,
		};
	},
}
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

::v-deep {
	.modal-content {
		max-width: 564px !important;
		width: calc(100% - 20px);

		.acao-experiencia-modal-container {
			padding: 2rem;
			border-radius: 1rem;
			background-color: white;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 2rem;


			.titulo,
			.experiencia,
			.info-extra {
				text-align: center;
				color: #173048;
			}

			.titulo {
				font-size: 1.75rem;
				line-height: 2rem;
			}
		}
	}
}
</style>
