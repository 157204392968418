<template>
	<div>
		<div class="columns">
			<transition name="fade">
				<div
					class="column is-10-tablet is-offset-1-tablet is-10-fullhd"
				>
					<Title>
						<div class="title-exp">
							<span @click="modalOpen = true">
								<Icon
									v-if="permiteAcessarExperiencias"
									class="icon-title-exp is-clickable"
									file="information"
									size="1rem"
								/>
							</span>
							Experiências
						</div>
					</Title>

					<div
						ref="start-page"
						tabindex="-1"
					/>

					<div v-if="permiteAcessarExperiencias">
						<ReguaExperiencia
							v-if="!isMobile"
						/>

						<div class="header-buttons">
							<div class="is-4  mr-4">
								<b-button
									type="is-primary"
									outlined
									:class="'tab-button' + ((activeTab == 0) ? ' active' : '')"
									@click="activeTab = 0"
								>
									<Icon
										class="primary-color tab-icon"
										file="experiencias/novas-experiencias"
										size="28px"
									/>

									<span>Novas experiências</span>
								</b-button>
							</div>
							<div class="is-4 mr-4">
								<b-button
									type="is-primary"
									outlined
									:class="'tab-button' + ((activeTab == 1) ? ' active' : '')"
									@click="activeTab = 1"
								>
									<Icon
										class="primary-color tab-icon"
										file="experiencias/minhas-experiencias"
										size="28px"
									/>

									<span>Minhas experiências</span>
								</b-button>
							</div>
							<div class=" is-4">
								<b-button
									type="is-primary"
									outlined
									:class="'tab-button encontro-especialista' + ((activeTab == 2) ? ' active' : '')"
									@click="activeTab = 2"
								>
									<Icon
										class="primary-color tab-icon"
										file="experiencias/experiencias_7"
										size="28px"
									/>

									<span>Encontros com especialista</span>
								</b-button>
							</div>
						</div>

						<div class="header-filters" v-if="activeTab !== 0">
							<UIInput
								id="filtro"
								class="column is-8 no-border"
								v-model="filtro.search"
								placeholder="Pesquisar..."
							>
								<template #addonBefore>
									<span class="button is-static addon-icon">
										<b-icon icon="magnify" />
									</span>
								</template>
							</UIInput>
							<UIInput
								id="status"
								class="column is-4 no-border select-status"
								v-model="filtro.status"
								type="select"
								:options="getStatus"
								/>
						</div>

						<div
							ref="panel"
							class="panel"
						>
							<transition name="fade">
								<div
									v-if="activeTab == 0"
									class="panel-content"
								>
									<ListagemExperiencias
										:data="getNovasExperiencias"
										area="novasExperiencias"
									/>
								</div>
							</transition>

							<transition name="fade">
								<div
									v-if="activeTab == 1"
									class="panel-content"
								>
									<ListagemExperiencias
										:data="filterMinhasExperiencias"
										area="minhasExperiencias"
									/>
								</div>
							</transition>

							<transition name="fade">
								<div
									v-if="activeTab == 2"
									class="panel-content"
								>
									<ListagemExperiencias
										:data="filterEncontrosComEspecialista"
										area="encontroEspecialista"
									/>
								</div>
							</transition>
						</div>
					</div>

					<div
						v-else-if="isVinculoSelecionado"
						class="py-5 is-flex is-justify-content-center"
					>
						<div class="is-flex is-flex-direction-column is-align-items-center empty-list">
							<div class="column is-5">
								<Icon
									class="primary-color"
									file="experiencias/experiencia_vinculo_incorreto"
									size="100%"
								/>
							</div>

							<h4 class="is-size-5 has-text-centered justify-with-image mb-4">
								Opa! Você não possui esse componente na sua grade curricular.
							</h4>

							<h4 class="is-size-5 is-12 has-text-centered">
								Para acompanhar ou demonstrar interesse nas experiências, é obrigatório que você
								<strong>selecione um vínculo de curso</strong> com este componente no filtro acima!
							</h4>
						</div>
					</div>

					<div
						v-else
						class="py-5 is-flex is-justify-content-center"
					>
						<div class="is-flex is-flex-direction-column is-align-items-center empty-list">
							<div class="column is-5">
								<Icon
									class="primary-color"
									file="experiencias/experiencia_selecione_vinculo"
									size="100%"
								/>
							</div>

							<h4 class="is-size-5 has-text-centered justify-with-image">
								Para acompanhar ou demonstrar interesse nas experiências, é obrigatório que você
								<strong>selecione um vínculo de curso</strong> no filtro acima!
							</h4>
						</div>
					</div>
				</div>
			</transition>

			<ModalExperiencia
				v-if="(modalOpen || !isModalVisualizado) && permiteAcessarExperiencias"
				@close="modalOpen = false"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UIInput from '@components/uiinput';
import Icon from '@components/Icon';
import ListagemExperiencias from '@components/experiencias/ListagemExperiencias.vue';
import ModalExperiencia from '@components/experiencias/ModalExperiencia.vue';
import ReguaExperiencia from '@components/experiencias/ReguaExperiencia.vue';
import Title from '@components/Title';

export default {
	name: 'Experiencias',

	components: {
		Icon,
		UIInput,
		ListagemExperiencias,
		ModalExperiencia,
		ReguaExperiencia,
		Title,
	},

	data() {
		return {
			activeTab: 0,
			modalOpen: false,
			filtro: {
				search: '',
				status: '',
			},
		};
	},

	computed: {
		...mapGetters('experiencias', [
			'getNovasExperiencias',
			'getMinhasExperiencias',
			'getEncontrosComEspecialista',
			'isLoading',
			'contemDados',
			'isModalVisualizado',
		]),
		...mapGetters('servicos', [
			'permiteAcessarExperiencias',
		]),
		...mapGetters('vinculosAcademicos', [
			'getVinculoSelecionado',
		]),
		...mapGetters('ui', [
			'isMobile',
		]),
		isVinculoSelecionado() {
			return !this.$store.getters['vinculosAcademicos/isVinculoTodos'];
		},
		getStatus() {
			return this.activeTab === 2
				? [
						{ label: 'Todas', value: '' },
						{ label: 'Concluídas', value: 'aprovado' },
						{ label: 'Acesso ao Ulife', value: 'matriculado' },
					]
				: [
						{ label: 'Todas', value: '' },
						{ label: 'Concluídas', value: 'aprovado' },
						{ label: 'Aguardando confirmação', value: 'aguardando aprovação' },
						{ label: 'Reprovadas', value: ['vinculo', 'reprovado'] },
						{ label: 'Não contemplado', value: ['interesse', 'reprovado'] },
						{ label: 'Acesso ao Ulife', value: 'matriculado' },
					]
		},
		filterMinhasExperiencias() {
			let filtered = this.getMinhasExperiencias;

			if (this.filtro.search) {
				filtered = filtered.filter(experiencia => {
					const {
						nome,
						descricao,
						tipo_experiencia,
					} = experiencia;
					const searchLower = this.filtro.search.toLowerCase();

					return nome.toLowerCase().includes(searchLower) ||
						descricao.toLowerCase().includes(searchLower) ||
						tipo_experiencia?.nome.toLowerCase().includes(searchLower);
				});
			}
			if (this.filtro.status) {
				filtered = filtered.filter(experiencia => {
					const status = this.filtro.status.split(',');
					if (status.length > 1) {
						const [key, value] = status;
						return experiencia[key]?.status === value;
					}
					return experiencia?.vinculo?.status === this.filtro.status ||
						experiencia?.interesse?.status === this.filtro.status;
				});
			}

			return filtered;
    },
		filterEncontrosComEspecialista() {
			let filtered = this.getEncontrosComEspecialista;

			if (this.filtro.search) {
				filtered = filtered.filter(experiencia => {
					const {
						nome,
						descricao,
						tipo_experiencia,
					} = experiencia;
					const searchLower = this.filtro.search.toLowerCase();

					return nome.toLowerCase().includes(searchLower) ||
						descricao.toLowerCase().includes(searchLower) ||
						tipo_experiencia?.nome.toLowerCase().includes(searchLower);
				});
			}

			if (this.filtro.status) {
				filtered = filtered.filter(experiencia => {
					return experiencia?.vinculo?.status === this.filtro.status;
				});
			}

			return filtered;
    }
	},

	watch: {
		getVinculoSelecionado() {
			if (!this.isVinculoSelecionado) {
				return;
			}

			if (!this.permiteAcessarExperiencias) {
				return;
			}

			this.todasExperiencias();
		},

		activeTab() {
			this.filtro = {
				search: '',
				status: '',
			}
		}
	},

	async created() {
		if (this.contemDados) {
			return;
		}

		await this.todasExperiencias();
	},

	methods: {
		...mapActions('experiencias', [
			'todasExperiencias',
		]),
	},

};
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

@media(max-width: 768px) {
	.header-buttons {
		display: flex;
		overflow-y: hidden;
		overflow-x: auto;
	}

	::v-deep .ui-input.has-addons .control {
		flex-direction: initial;
		padding-top: 0;
	}
}

::v-deep .header-filters {
	display: flex;
	justify-content: center;
	gap: 8px;
}

::v-deep .header-filters > .column {
	padding: 0.75rem 0 0.75rem;
}

::v-deep .addon-icon {
	border: none;
	background-color: transparent;
	padding: 10px 0 0;
	width: 10% !important;
}

::v-deep .no-border .control {
	border: solid 1px #C2C2C2 !important;
}

::v-deep .select-status .control {
	padding-top: 0 !important;
}

::v-deep .icon-title-exp {
	margin-right: 1rem;
}

::v-deep .title-exp {
	display: flex !important;
}

::v-deep .tab-icon {
	max-height: 28px;
}

::v-deep .tab-icon path {
	fill: var(--primary) !important;
}

::v-deep .active .tab-icon path  {
	fill: #fff !important;
}


::v-deep .encontro-especialista .tab-icon path {
	fill: var(--dark-blue) !important;
}

::v-deep .encontro-especialista.active .tab-icon path {
	fill: #fff !important;
}

.header-buttons {
	text-align: center;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding-left: 0;
	padding-right: 0;
	margin: 2.5rem 0;
}

::v-deep .tab-button {
	border-radius: 1rem;
	background-color: #fff !important;
	border-color: transparent !important;
	min-height: 5rem;
	width: 17rem;
	font-size: 1.25rem;
	font-weight: 400;


	&:hover {
		color: var(--white) !important;
		background-color: var(--primary-hover) !important;

		.tab-icon path {
			fill: #fff !important;
		}
	}

	&.button.is-primary.is-focused,
	&.button.is-primary:focus {
		color: var(--primary);
		box-shadow: unset !important;
	}
}

.active {
	color: #fff !important;
	background-color: var(--primary) !important;
}

::v-deep .encontro-especialista {
	min-width: 210px;
	color: var(--dark-blue) !important;

	&:hover {
		.tab-icon path {
			fill: #fff !important;
		}
		color: #fff !important;
		background-color: var(--dark-blue) !important;
	}

	&.active {
		color: #fff !important;
		background-color: var(--dark-blue) !important;
		.tab-icon path {
			fill: #fff !important;
		}
	}
}

::v-deep {
	.panel {
		border-radius: 1rem;
	}
}
</style>
