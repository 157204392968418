import { render, staticRenderFns } from "./ReguaExperiencia.vue?vue&type=template&id=6992c27e&scoped=true&"
import script from "./ReguaExperiencia.vue?vue&type=script&lang=js&"
export * from "./ReguaExperiencia.vue?vue&type=script&lang=js&"
import style0 from "./ReguaExperiencia.vue?vue&type=style&index=0&id=6992c27e&lang=scss&scoped=true&"
import style1 from "./ReguaExperiencia.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6992c27e",
  null
  
)

export default component.exports