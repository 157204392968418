<template>
	<div class="acao-experiencia-status-container">
		<div
			class="info-container"
		>
			<b-tooltip
				multilined
				size="is-large"
				type="is-white"
			>
				<b-icon
					icon="information-outline"
					custom-size="mdi-1rem"
				/>

				<template #content>
					<span>
						Você pode participar de <strong>diversas experiências
							simultaneamente</strong> e será notificado caso
						seja contemplado. As vagas são <strong>limitadas</strong>.
					</span>
				</template>
			</b-tooltip>

			<center>Você já está concorrendo para participar desta experiência!</center>
		</div>

		<div class="interesse-container">
			<Icon
				:file="icone"
				size="95:80"
			/>

			<strong>
				<span class="interesse-label">
					Nível de interesse:
				</span>

				<span class="interesse-value">
					{{ descricao }}
				</span>
			</strong>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@components/Icon';
import {
	InteresseValorEnum,
	InteresseIconeSelecionadoEnum,
	InteresseNomeEnum,
} from '@enum/experiencias/interesse';

export default {
	name: 'AcaoExperienciaStatus',

	components: {
		Icon,
	},

	props: {
		id: {
			required: true,
			type: Number,
		},
	},

	computed: {
		...mapGetters(
			'experiencias',
			{
				altoSelecionado: 'getInteresseAlto',
				muitoAltoSelecionado: 'getInteresseMuitoAlto',
			}
		),

		interesse() {
			if (this.muitoAltoSelecionado.id == this.id) {
				return InteresseValorEnum.MUITO_ALTO;
			}

			if (this.altoSelecionado.id == this.id) {
				return InteresseValorEnum.ALTO;
			}

			return InteresseValorEnum.MEDIO;
		},

		icone() {
			return InteresseIconeSelecionadoEnum[this.interesse];
		},

		descricao() {
			return InteresseNomeEnum[this.interesse];
		}
	},
}
</script>

<style lang="scss" scoped>
.acao-experiencia-status-container {
	display: flex;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
}

.info-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
}

.interesse-container {
	display: flex;
	gap: 1.5rem;
	flex-direction: column;
	align-items: center;

	::v-deep .custom-icon {
		line-height: 0;
	}
}

.interesse-label {
	color: #173048;
}

.interesse-value {
	color: var(--primary);
}

::v-deep {
	.b-tooltip.is-white {
		.tooltip-content {
			background-color: white !important;
			color: #525252 !important;
			padding: 1rem;

			&::before {
				filter: drop-shadow(0px 3px 1px rgba(0, 1, 0, 0.2));
			}
		}
	}
}
</style>
