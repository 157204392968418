<template>
	<div
		class="status-experiencia-ulife-container"
	>
		<div class="ulife-dados">
			<div class="info-container">
				<b-icon
					icon="information-outline"
					custom-size="mdi-1rem"
				/>

				<span
					v-if="disponivel"
					class="has-text-centered"
				>
					Sua matrícula está confirmada!<br>
					No horário do encontro, acesse o Ulife para participar
				</span>

				<span
					v-else
					class="has-text-centered"
				>
					Sua matrícula está confirmada!<br>
					No dia e horário do encontro, o acesso será liberado
				</span>
			</div>

			<Icon
				:file="iconeUlife"
				size="80:80"
			/>
		</div>

		<b-button
			class="botao-ulife"
			rounded
			:disabled="!disponivel"
			@click="acessarUlife"
		>
			Acessar
		</b-button>
	</div>
</template>

<script>
import moment from 'moment';
import Icon from '@components/Icon';

export default {
	name: 'StatusExperienciaUlife',

	components: {
		Icon,
	},

	props: {
		experiencia: {
			required: true,
			type: Object,
		},
	},

	computed: {
		disponivel() {
			const agora = moment();

			return this.experiencia.horarios.some(horario => {
				return moment(horario.data_inicio).isSameOrBefore(agora, 'day');
			});
		},

		iconeUlife() {
			return this.disponivel
				? 'experiencias/status/ulife'
				: 'experiencias/status/ulife_desabilitado'
			;
		},
	},

	methods: {
		async acessarUlife() {
			try {
				this.$store.dispatch('ui/loading', true);

				if (!this.$store.getters['servicos/getUrlSso']) {
					await this.$store.dispatch('servicos/callSsoUrl');
				}

				const url = this.$store.getters['servicos/getUrlSso'];

				if (String(url).length == 0) {
					throw new Error();
				}

				await this.$store.dispatch('servicos/clearSsoUrl');

				window.open(url, '_blank');
			} catch (e) {
				this.$store.dispatch(
					'ui/toast/openError',
					'Erro ao acessar o Ulife, tente novamente mais tarde!'
				);

				return;
			} finally {
				this.$store.dispatch('ui/loading', false);
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.status-experiencia-ulife-container {
	display: flex;
	gap: 1.5rem;
	flex-direction: column;
	align-items: center;
}

.ulife-dados {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.125rem;

	::v-deep .custom-icon {
		line-height: 0;
	}
}

.info-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
}

.botao-ulife {
	background-color: #173048;
	color: #FFFFFF;
	border: unset;
}
</style>
