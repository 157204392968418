<template>
	<div
		class="status-experiencia-container"
		:class="['status-' + statusInterno]"
	>
		<b-tooltip
			type="is-black"
			multilined
			size="is-large"
		>
			<div class="status-experiencia-content">
				<div class="status-descricao">
					{{ statusFormatado }}
				</div>

				<Icon
					:file="icone"
					size="80:80"
				/>

				<div
					v-if="interesseFormatado"
					class="nivel-interesse"
				>
					<span>
						Nível de interesse:
					</span>

					<span class="interesse-descricao">
						{{ interesseFormatado }}
					</span>
				</div>

				<div v-else />
			</div>

			<template #content>
				<p><strong>Status: {{ statusFormatado }}</strong></p>

				<p
					v-if="statusDescricao"
					class="mt-4"
				>
					{{ statusDescricao }}
				</p>
			</template>
		</b-tooltip>
	</div>
</template>

<script>
import Icon from '@components/Icon';
import { InteresseNomeEnum, InteresseStatusEnum } from '@enum/experiencias/interesse';
import { ExperienciaTipoEnum } from '@enum/experiencias/experiencia';
import { VinculoStatusEnum } from '@enum/experiencias/vinculo';

const StatusEnum = Object.freeze({
	AGUARDANDO: 'aguardando',
	NAO_CONTEMPLADO: 'nao_contemplado',
	MATRICULADO: 'matriculado',
	CONCLUIDO: 'concluido',
	REPROVADO: 'reprovado',
});

const StatusInteresseNomeMap = Object.freeze({
	[InteresseStatusEnum.AGUARDANDO]: 'Aguardando confirmação',
	[InteresseStatusEnum.REPROVADO]: 'Não contemplado',
});

const StatusInteresseDescricaoMap = Object.freeze({
	[InteresseStatusEnum.AGUARDANDO]: 'Recebemos sua inscrição e estamos verificando se há vaga disponível',
	[InteresseStatusEnum.REPROVADO]: 'Infelizmente, não há mais vagas disponíveis na Experiência desejada.\r\nSendo assim, não será possível a sua participação no encontro síncrono',
});

const StatusVinculoNomeMap = Object.freeze({
	[VinculoStatusEnum.APROVADO]: 'Aprovado',
	[VinculoStatusEnum.REPROVADO]: 'Reprovação por Ausência',
});

const StatusVinculoDescricaoMap = Object.freeze({
	[VinculoStatusEnum.APROVADO]: 'Você concluiu a Experiência e a respectiva carga horária já foi computada',
	[VinculoStatusEnum.REPROVADO]: 'Você se inscreveu na Experiência, teve a matrícula confirmada, mas não participou do encontro síncrono.\r\nSendo assim, a respectiva carga horária não será computada',
});

const IconMap = Object.freeze({
	[StatusEnum.AGUARDANDO]: 'experiencias/status/clock',
	[StatusEnum.NAO_CONTEMPLADO]: 'experiencias/status/x',
	[StatusEnum.CONCLUIDO]: 'experiencias/status/check',
	[StatusEnum.REPROVADO]: 'experiencias/status/x',
});

export default {
	name: 'StatusExperienciaInfo',

	components: {
		Icon,
	},

	props: {
		experiencia: {
			required: true,
			type: Object,
		},

		tipo: {
			required: true,
			type: String,
		},

		status: {
			required: true,
			type: String,
		},

		statusInterno: {
			required: true,
			type: String,
		},
	},

	computed: {
		statusFormatado() {
			if (this.tipo === ExperienciaTipoEnum.INTERESSE) {
				return StatusInteresseNomeMap[this.status];
			}

			return StatusVinculoNomeMap[this.status];
		},

		statusDescricao() {
			if (this.tipo === ExperienciaTipoEnum.INTERESSE) {
				return StatusInteresseDescricaoMap[this.status];
			}

			return StatusVinculoDescricaoMap[this.status];
		},

		icone() {
			return IconMap[this.statusInterno];
		},

		interesseFormatado() {
			const prioridade = this.experiencia?.interesse?.prioridade;

			if (!prioridade) {
				return '';
			}

			return InteresseNomeEnum[prioridade] || '';
		},
	},
}
</script>

<style lang="scss" scoped>
.status-concluido {
	--bg-color: #00AE8E0D;
	--icon-color: #00796A;
	--tooltip-bg: #00796A;
	--tooltip-color: #FFFFFF;
}

.status-aguardando {
	--bg-color: #FFB1000D;
	--icon-color: #FFB100;
	--tooltip-bg: #FFB100;
	--tooltip-color: #173048;
}

.status-reprovado {
	--bg-color: #C93F580D;
	--icon-color: #C93F58;
	--tooltip-bg: #C93F58;
	--tooltip-color: #FFFFFF;
}

.status-nao_contemplado {
	--bg-color: #7625820D;
	--icon-color: #762582;
	--tooltip-bg: #762582;
	--tooltip-color: #FFFFFF;
}

.status-experiencia-container {
	display: flex;
	flex-direction: column;
	align-items: center;

		.status-experiencia-content {
			background-color: var(--bg-color);
		}

		::v-deep .custom-icon path {
			fill: var(--icon-color) !important;
		}

	::v-deep .tooltip-content {
		background-color: var(--tooltip-bg) !important;
		border-radius: 0.5rem;
		box-shadow: unset;
		padding: 0.5rem;
		font-size: 1rem;
		line-height: 1.5rem;
		text-align: left;
		white-space: pre-line;

		&::before {
			border-top-color: var(--tooltip-bg) !important;
		}

		strong,
		span {
			color: var(--tooltip-color);
		}
	}
}

.status-experiencia-content {
	min-width: 225px;
	padding: 1rem;
	border-radius: 0.5rem;
	display: flex;
	gap: 1.5rem;
	flex-direction: column;
	align-items: center;
	color: #173048;
}

.status-descricao {
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 600;
}

.interesse-descricao {
	color: var(--primary);
}
</style>
