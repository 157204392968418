<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="exp-card">
		<hr
			v-if="showDivider"
			class="divider-horizontal"
		>

		<div class="exp-header mb-4">
			<Icon
				class="exp-icon mr-3"
				:file="'experiencias/experiencias_' + experiencia.tipo_experiencia.id"
				size="48"
			/>

			<div>
				<h1 class="exp-title">
					{{ experiencia.nome }}
				</h1>

				<p class="exp-type">
					<b-tooltip>
						<span
							class="tooltip-exp-type"
						>
							{{ experiencia.tipo_experiencia.nome }}
						</span>

						<template #content>
							<div class="tooltip-exp-type-desc m-3">
								<div class="is-size-6 mb-5 has-text-weight-bold">
									{{ experiencia.tipo_experiencia.nome }}
								</div>

								<p>{{ experiencia.tipo_experiencia.descricao }}</p>
							</div>
						</template>
					</b-tooltip>

					<span class="exp-id ml-4">
						{{ exp_id }}
					</span>
				</p>
			</div>
		</div>

		<div class="exp-body columns">
			<div class="column is-8 exp-info">
				<div
					v-if="exp_ch"
					class="exp-ch mb-2"
				>
					<span>
						Carga Horária: <strong>{{ exp_ch }} | Online</strong>
					</span>
				</div>

				<div
					v-if="possuiHorarios"
					class="exp-horarios mb-2"
				>
					<Icon
						class="pr-2 exp-icon"
						file="calendar"
						size="15px"
					/>

					<span class="exp-primeiro-horario">{{ primeiroHorario }}</span>

					<b-tooltip v-if="possuiMaisHorarios">
						<span
							class="tooltip-horarios pl-2"
						>
							+{{ possuiMaisHorarios }} encontros
						</span>

						<template #content>
							<div class="tooltip-dates">
								<div class="mb-1">
									Encontros:
								</div>

								<div
									v-for="(horario) in experiencia.horarios"
									:key="horario.id"
									class="tooltip-date-line"
								>
									<p class="pr-1 exp-weekday">
										{{ parseWeekDay(horario.data_inicio) }}
									</p>

									<p class="pr-1 exp-hour">
										{{ parseHour(horario.data_inicio) }}
									</p>

									<p class="pr-1 exp-date">
										{{ parseDate(horario.data_inicio) }}
									</p>

									<br>
								</div>
							</div>
						</template>
					</b-tooltip>
				</div>

				<div
					v-if="limite_inscricao && exibeDataInscricao"
					class="exp-limit mb-2"
				>
					<strong>Inscrições até:</strong> {{ limite_inscricao }}
				</div>

				<div
					v-if="experiencia.descricao"
					:class="{'expandable-desc': cardExpande, 'exp-desc': true}"
				>
					<strong>Descrição: </strong>

					<div v-if="cardExpande">
						<p
							v-if="exibeDescResumida"
							class="desc-resumida"
							@click="exibeDescResumida = false"
							v-html="descResumida"
						/>

						<p
							v-else
							@click="exibeDescResumida = true"
							v-html="experiencia.descricao"
						/>
					</div>

					<div v-else>
						<p
							v-html="experiencia.descricao"
						/>
					</div>
				</div>
			</div>

			<div class="column is-4 exp-actions">
				<AcaoExperienciaModal
					v-if="exibeModalConfirmacao"
					:experiencia="experiencia"
					:interesse="exibeModalConfirmacao"
					@close="exibeModalConfirmacao = false"
				/>

				<AcaoExperiencia
					v-if="area === 'novasExperiencias'"
					:id="experiencia.id"
					@confirmado="exibeModalConfirmacao = $event"
				/>

				<StatusExperiencia
					v-else
					:experiencia="experiencia"
				/>
			</div>
		</div>

		<b-button
			v-if="cardExpande"
			class="arrow-button"
			@click="exibeDescResumida = !exibeDescResumida"
		>
			<b-icon
				size="is-large"
				class="exp-desc-icon"
				:icon="exibeDescResumida ? 'menu-down' : 'menu-up'"
			/>
		</b-button>
	</div>
</template>

<script>
import moment from 'moment';
import Icon from '@components/Icon';
import AcaoExperiencia from '@components/experiencias/AcaoExperiencia';
import AcaoExperienciaModal from '@components/experiencias/AcaoExperienciaModal';
import StatusExperiencia from '@components/experiencias/StatusExperiencia';
import { mapGetters } from 'vuex';

export default {
	name: 'ItemExperiencia',

	components: {
		Icon,
		AcaoExperiencia,
		AcaoExperienciaModal,
		StatusExperiencia,
	},

	props: {
		experiencia: {
			type: Object,
			required: true,
			default: () => {},
		},
		showDivider: {
			type: Boolean,
			default: false
		},
		area: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			exibeDescResumida: true,
			exibeModalConfirmacao: false,
		};
	},

	computed: {
		...mapGetters('experiencias', [
			'isLoading',
			'getCodigoIes'
		]),
		exp_ch() {
			try {
				return this.experiencia.carga_horaria.toString().split('.')[0] + 'h';
			} catch (error) {
				return false;
			}
		},
		exp_id() {
			return this.getCodigoIes + '-' + this.getCodigoIes.replace("DL", "EXP") + '-' + this.experiencia.id;
		},
		limite_inscricao(){
			try {
				return this.parseDate(this.experiencia.data_termino);
			} catch (error) {
				return false;
			}
		},
		possuiHorarios(){
			try {
				return this.experiencia.horarios.length > 0;
			} catch (error) {
				return false;
			}
		},
		possuiMaisHorarios(){
			try {
				let mais = this.experiencia.horarios.length - 1;
				if(mais > 0) {
					return mais
				} else {
					return false;
				}
			} catch (error) {
				return false;
			}
		},
		primeiroHorario(){
			return this.parseDateHour(this.experiencia.horarios[0].data_inicio);
		},
		cardExpande(){
			try {
				return this.experiencia.descricao.length >= 200;
			} catch (error) {
				return false
			}
		},
		descResumida(){
			try {
				let resumo = this.experiencia.descricao.substring(0, 200);
				resumo += '... <span class="vermais-button">Ver mais.</span>'
				return resumo;
			} catch (error) {
				return false;
			}
		},
		exibeDataInscricao(){
			return this.area == 'novasExperiencias';
		},
	},

	methods: {
		parseDateHour(date){
			return moment(date).format('HH:mm | DD/MM/YYYY');
		},
		parseDate(date){
			return moment(date).format('DD/MM/YYYY');
		},
		parseHour(date){
			return moment(date).format('HH:mm');
		},
		parseWeekDay(date) {
			const dias = ['DOMINGO', 'SEGUNDA-FEIRA', 'TERÇA-FEIRA', 'QUARTA-FEIRA', 'QUINTA-FEIRA', 'SEXTA-FEIRA','SÁBADO']
			return dias[moment(date).format('e')];
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

.exp-header {
	text-align: left !important;
	display: flex;

	.exp-icon {
		display: flex;
		align-items: center;
	}
}
.exp-body{
	text-align: left !important;
}
.exp-title {
	font-size: larger;
	font-weight: 600;
}
.exp-id {
	color: #c2c2c2;
}
::v-deep .exp-icon path{
	fill: var(--primary);
}
.exp-type {
	font-weight: 600;
	color: var(--primary)
}
.exp-horarios {
	display: flex;
}
.exp-primeiro-horario{
	font-weight: 500;
}
.tooltip-horarios {
	color: var(--primary);
	text-decoration: underline;
}
.exp-weekday{
	display: inline-flex;
	min-width: 120px;
}
.exp-hour{
	display: inline-flex;
	min-width: 50px;
}
.exp-date{
	display: inline-flex;
	min-width: 60px;
}
.tooltip-dates{
	min-width: 250px;
}

.tooltip-date-line {
	text-align: center;
}
.exp-desc.expandable-desc{
	cursor: pointer;
}

.exp-desc-icon {
	color: var(--primary);
}

.exp-card {
	text-align: center;
}

.arrow-button {
	border: 0;
	width: 100%;
	max-width: none;
	:focus {
		border: 0 !important;
	}
}

.arrow-button:focus:not(:active), .arrow-button.is-focused:not(:active) {
	box-shadow: none !important;
}

::v-deep{
	.tooltip-content{
		background-color: var(--primary) !important;
		color: #fff !important;
		}
		.b-tooltip.is-top.is-primary .tooltip-content::before {
			border-top-color: var(--primary) !important;
		}
		@media(max-width: 768px) {
			.exp-horarios .b-tooltip.is-top .tooltip-content {
				left: 20% !important;
			}
		}
		@media(max-width: 768px) {
			.exp-type .b-tooltip.is-top .tooltip-content {
				left: 3rem !important;
			}
		}
}

.tooltip-exp-type-desc {
	max-width: 20rem;
	min-width: 19rem;
  white-space: normal;
  word-wrap: break-word;
}

</style>

<style>
.vermais-button {
	color: var(--primary);
	text-decoration: underline;
}
</style>
