<!-- eslint-disable vue/no-v-html -->
<template>
	<transition name="fade">
		<div
			v-if="!isLoading"
			:class="{'encontro-especialista-container': area == 'encontroEspecialista'}"
		>
			<div
				v-if="data.length == 0 && area == 'novasExperiencias'"
				:class="'py-5 is-flex is-justify-content-center'"
			>
				<div class="is-flex is-flex-direction-column is-align-items-center empty-list ">
					<div class="column is-5">
						<Icon
							class="primary-color"
							file="experiencias/experiencia_selecione_vinculo"
							size="100%"
						/>
					</div>

					<h4 class="is-size-5 has-text-centered justify-with-image mb-4">
						Opa! Você não possui nenhuma experiência disponível para inscrição.
					</h4>

					<h4 class="is-size-5 is-12 has-text-centered">
						Aguarde a liberação das experiências para selecionar seu interesse e concorrer.
					</h4>
				</div>
			</div>

			<div
				v-if="data.length == 0 && area == 'minhasExperiencias'"
				:class="'py-5 is-flex is-justify-content-center'"
			>
				<div class="is-flex is-flex-direction-column is-align-items-center empty-list ">
					<div class="column is-5">
						<Icon
							class="primary-color"
							file="experiencias/experiencia_selecione_vinculo"
							size="100%"
						/>
					</div>

					<h4 class="is-size-5 has-text-centered justify-with-image mb-4">
						Opa! Você ainda não tem nenhuma experiência em andamento.
					</h4>

					<h4 class="is-size-5 is-12 has-text-centered">
						Selecione seu interesse para as "Novas Experiências" e concorra para visualizar o andamento aqui.
					</h4>
				</div>
			</div>

			<div
				v-if="data.length == 0 && area == 'encontroEspecialista'"
				:class="'py-5 is-flex is-justify-content-center'"
			>
				<div class="is-flex is-flex-direction-column is-align-items-center empty-list ">
					<div class="column is-5">
						<Icon
							class="primary-color"
							file="experiencias/experiencia_vinculo_incorreto"
							size="100%"
						/>
					</div>

					<h4 class="is-size-5 has-text-centered justify-with-image mb-4">
						Opa! Você ainda não possui nenhum encontro agendado com o especialista.
					</h4>

					<h4 class="is-size-5 is-12 has-text-centered">
						Aguarde a liberação das experiências em seu componente curricular.
					</h4>
				</div>
			</div>

			<ItemExperiencia
				v-for="(experiencia, index) in data"
				:key="experiencia.id"
				:experiencia="experiencia"
				:show-divider="index != 0"
				:area="area"
				class="pt-3"
			/>
		</div>
		<b-skeleton v-else />
	</transition>
</template>

<script>
import ItemExperiencia from '@components/experiencias/ItemExperiencia'
import { mapGetters } from 'vuex';
import Icon from '@components/Icon';

export default {
	name: 'ListagemExperiencias',

	components: {
		ItemExperiencia,
		Icon,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
		area: {
			type: String,
			required: true
		}

	},
	computed: {
		...mapGetters('experiencias', [
			'isLoading',
		]),
	},

	methods: {

	},
};
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

.encontro-especialista-container {
	::v-deep {
		.exp-icon path{
			fill: var(--dark-blue);
		}
		.exp-type {
			color: var(--dark-blue);
		}
		.tooltip-content{
		background-color:  var(--dark-blue) !important;
		}
		.b-tooltip.is-top.is-primary .tooltip-content::before {
			border-top-color: var(--dark-blue) !important;
		}
	}
}

hr {
	background-color: #c2c2c2;
	height: 1px !important;
}

@media (max-width: 1020px) {
	::v-deep .center-on-mobile {
		text-align: center;
		align-items: center !important;
	}

	::v-deep .left-on-mobile {
		text-align: left;
		padding-left: 0px !important;
	}

	.minimum-height {
		min-height: inherit !important;
	}
}

.minimum-height {
	min-height: 14rem;
}

</style>
