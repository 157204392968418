<template>
	<div class="regua-experiencia new-colors">
		<div class="regua-titulo">
			<h3>Progresso de experiências</h3>

			<span
				v-if="isLoading"
			>
				<b-skeleton
					animated
					position="is-right"
					width="4rem"
					height="1.75rem"
				/>
			</span>

			<span
				v-else
				class="regua-porcentagem"
			>
				{{ porcentagem }}%
			</span>
		</div>

		<div
			class="regua-progresso"
			:class="{
				'concluido': porcentagem >= 100,
			}"
		>
			<template
				v-if="experiencias.length"
			>
				<template
					v-for="experiencia in experiencias"
				>
					<b-tooltip
						:key="'item-' + experiencia.id"
						multilined
						append-to-body
						size="is-large"
						:type="experiencia.encontro_expecialista ? 'is-black' : 'is-white'"
						class="regua-experiencia-item"
						:class="experiencia.aconteceu ? 'regua-experiencia-item-aconteceu' : 'regua-experiencia-item'"
					>
						<div
							class="progresso-item"
							:class="{
								'encontro-expecialista': experiencia.encontro_expecialista,
								'concluido': experiencia.concluido
							}"
						>
							<Icon
								v-if="!experiencia.encontro_expecialista"
								:file="experiencia.icone"
								size="26"
							/>
						</div>

						<template #content>
							<p class="has-text-weight-bold mb-4">
								{{ experiencia.nome_tipo_experiencia }}
							</p>

							<p>{{ experiencia.nome }}</p>

							<p>Carga horária: {{ experiencia.carga_horaria }}</p>

							<p v-if="experiencia.aconteceu">
								Conclusão: {{ experiencia.data_termino.format('DD/MM/YYYY') }}
							</p>

							<p v-else>
								Data do encontro: {{ experiencia.data_termino.format('DD/MM/YYYY') }}
							</p>
						</template>
					</b-tooltip>

					<div
						:key="'espacador-' + experiencia.id"
						class="espacador"
					/>
				</template>
			</template>

			<div
				v-else
				class="espacador"
			/>
		</div>

		<div class="regua-rodape">
			<span
				v-if="isLoading"
			>
				<b-skeleton
					animated
					position="is-right"
					width="13rem"
					height="1.25rem"
				/>
			</span>

			<span v-else>
				{{ cursada }}h/{{ total }}h concluídas
			</span>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import Icon from '@components/Icon.vue';
import { ExperienciaTipoEnum } from '@enum/experiencias/experiencia'
import { VinculoStatusEnum } from '@enum/experiencias/vinculo'

const cargaHorariaParser = ch => {
	let [ horas, minutos ] = ch.split('.');

	minutos = Math.floor(parseInt(minutos || 0, 10) * 0.60);

	return horas
		+ 'h'
		+ minutos.toString(10).padStart(2, '0')
		+ 'm'
	;
};

const expBuilder = encontro_expecialista => {
	const agora = moment();

	return exp => {
		const data_termino = exp.horarios
			.map(hr => moment(hr.data_termino))
			.reduce((a, c) => c > a ? c : a, moment(0));

		return {
			id: exp.id,
			id_tipo_experiencia: exp.id_tipo_experiencia,
			nome: exp.nome,
			nome_tipo_experiencia: exp.tipo_experiencia.nome,
			carga_horaria: cargaHorariaParser(exp.carga_horaria),
			data_termino,
			encontro_expecialista,
			aconteceu: data_termino < agora,
			concluido: exp.vinculo.status === VinculoStatusEnum.APROVADO,
			icone: 'experiencias/experiencias_' + exp.id_tipo_experiencia,
		};
	};
};

const expFilter = exp =>
	exp.tipo === ExperienciaTipoEnum.VINCULO
	&& (
		exp.vinculo.status === VinculoStatusEnum.MATRICULADO
		|| exp.vinculo.status === VinculoStatusEnum.APROVADO
	)
;

export default {
	name: 'ReguaExperiencia',

	components: {
		Icon,
	},

	computed: {
		isLoading() {
			return this.$store.getters['experiencias/isLoading'];
		},

		total() {
			return this.$store.getters['experiencias/getCargaHorariaTotal'] || 0;
		},

		cursada() {
			return this.$store.getters['experiencias/getCargaHorariaCursada'] || 0;
		},

		porcentagem() {
			const fraction = this.cursada / this.total;

			return Math.floor((fraction || 0) * 100);
		},

		experiencias() {
			if (this.isLoading) {
				return [];
			}

			let exps = [
				...this.$store.getters['experiencias/getMinhasExperiencias']
					.filter(expFilter)
					.map(expBuilder(false)),
				...this.$store.getters['experiencias/getEncontrosComEspecialista']
					.filter(expFilter)
					.map(expBuilder(true)),
			]
				.sort((a, b) => a.data_termino > b.data_termino ? 1 : -1);

			return exps;
		}
	}
}
</script>

<style lang="scss" scoped>
.regua-experiencia {
	padding: 1.5rem;
	border-radius: 1.5rem;
	background-color: #FFFFFF;
	display: flex;
	gap: 1.5rem;
	flex-direction: column;
}

.regua-titulo {
	line-height: 2rem;
	display: flex;
	justify-content: space-between;

	h3 {
		font-size: 1.5rem;
		font-weight: 700;
		color: #173048;
	}

	.regua-porcentagem {
		font-size: 1.75rem;
		color: var(--primary);
		font-weight: bold;
	}
}

.regua-progresso {
	display: flex;
	align-items: center;
	// height: 2.5rem;
	overflow-x: auto;

	.progresso-item {
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 2.5rem;
		border: 1px solid var(--primary);
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 0;

		&.encontro-expecialista {
			width: 1.25rem;
			height: 1.25rem;
			border-color: #173048;
		}

		&.concluido {
			box-shadow: none;
			background-color: var(--primary);

			&.encontro-expecialista {
				background-color: #173048;
			}

			::v-deep {
				.custom-icon svg path {
					fill: #FFFFFF;
				}
			}
		}

		::v-deep {
			.custom-icon {
				width: 26px;
				height: 26px;

				svg path {
					fill: var(--primary);
				}
			}
		}
	}

	.espacador {
		min-width: 1.125rem;
		border-top: 1px var(--primary) dashed;

		&:last-child {
			flex-grow: 1;
		}
	}

	&.concluido .espacador,
	.regua-experiencia-item-aconteceu + .espacador:not(:last-child) {
		border-top-style: solid;
	}
}

.regua-rodape {
	display: flex;
	justify-content: flex-end;
	font-size: 1.25rem;
	line-height: 1.5rem;
	color: #173048;
}
</style>

<style lang="scss">
// Sim, não é "scoped" de proposíto.
// Por causa do overflow-x nos itens, o tooltip ficaria escondido, fazendo a
// necessidade do atributo "append-on-body", desacoplando totalmente o tooltip
// do componente, necessitando de uma regra global
.b-tooltip.regua-experiencia-item {
	.tooltip-content {
		padding: 1rem;
		box-shadow: none;
		text-align: left;

		&::before {
			filter: none;
		}
	}

	&.is-white {
		.tooltip-content {
			background-color: var(--primary) !important;
			color: #FFFFFF !important;

			&::before {
				border-top-color: var(--primary);
			}
		}
	}

	&.is-black {
		.tooltip-content {
			background-color: #173048 !important;
			color: #FFFFFF !important;

			&::before {
				border-top-color: #173048;
			}
		}
	}
}
</style>
