<template>
	<b-modal
		v-if="!$store.getters['ui/isLoading']"
		v-model="model"
		class="modal-new-login"
		:can-cancel="false"
	>
		<div class="card">
			<div class="card-content">
				<div class="pt-5 is-vcentered is-centered">
					<div class="columns is-justify-content-center is-mobile is-multiline steps">
						<div
							v-for="(step, index) in steps"
							:key="index"
							:class="['step column', { active: index <= activeStep }]"
						/>
					</div>
					<div :class="'is-flex is-justify-content-center'">
						<div
							v-if="activeStep == 0"
							key="0"
							class="is-flex is-flex-direction-column is-align-items-center empty-list"
						>
							<div class="is-5">
								<Icon
									class="primary-color"
									file="experiencias/modal-experiencia-1"
									size="200px"
								/>
							</div>

							<h3 class="has-text-centered justify-with-image has-text-weight-bold">
								Boas vindas à sua Área de Experiências!
							</h3>
							<h5 class="my-5 has-text-centered justify-with-image has-text-weight-bold">
								Visualize Ofertas de Experiências
							</h5>

							<p class="modal-desc is-12 has-text-centered">
								Aqui você poderá demonstrar seu interesse e concorrer a participação em diversas experiências que agregarão seu futuro profissional e também acompanhar seu progresso.
							</p>
							<div class="has-text-centered is-flex-tablet modal-btns">
								<b-button
									class="modal-btn"
									type="is-primary"
									rounded
									@click="nextStep"
								>
									Continuar
								</b-button>
							</div>
						</div>
						<div
							v-if="activeStep == 1"
							key="1"
							class="is-flex is-flex-direction-column is-align-items-center empty-list"
						>
							<div class="is-5">
								<Icon
									class="primary-color"
									file="experiencias/modal-experiencia-2"
									size="200px"
								/>
							</div>

							<h3 class="has-text-centered justify-with-image has-text-weight-bold">
								Diversidade de opções disponíveis
							</h3>
							<h5 class="my-5 has-text-centered justify-with-image has-text-weight-bold">
								Viagem, Roda Viva, Dual e Problematização
							</h5>
							<p class="modal-desc is-12 has-text-centered">
								Desenvolva suas skills através de eventos, debates, cursos e muito mais em experiências online ou presenciais. <span class="has-text-weight-bold">As vagas são limitadas.</span>
							</p>
							<div class="has-text-centered is-flex-tablet modal-btns">
								<b-button
									class="mt-4-mobile modal-btn"
									type="is-primary"
									rounded
									outlined
									@click="prevStep"
								>
									Anterior
								</b-button>
								<b-button
									class="modal-btn"
									type="is-primary"
									rounded
									@click="nextStep"
								>
									Continuar
								</b-button>
							</div>
						</div>
						<div
							v-if="activeStep == 2"
							key="2"
							class="is-flex is-flex-direction-column is-align-items-center empty-list"
						>
							<div class="is-5">
								<Icon
									class="primary-color"
									file="experiencias/modal-experiencia-3"
									size="200px"
								/>
							</div>

							<h3 class="has-text-centered justify-with-image has-text-weight-bold">
								Ordene por nível de interesse
							</h3>
							<p class="my-5 has-text-centered justify-with-image has-text-weight-bold">
								Médio, Alto e Muito Alto
							</p>

							<p class="modal-desc is-12 has-text-centered">
								Ao seleciona uma dessas opções, você estará concorrendo a participação da experiência e será notificado caso seja contemplado.<br><span class="has-text-weight-bold">Fique atento à data limite de inscrições.</span>
							</p>
							<div class="has-text-centered is-flex-tablet modal-btns">
								<b-button
									class="mt-4-mobile modal-btn"
									type="is-primary"
									rounded
									outlined
									@click="prevStep"
								>
									Anterior
								</b-button>
								<b-button
									class="modal-btn"
									type="is-primary"
									rounded
									@click="nextStep"
								>
									Continuar
								</b-button>
							</div>
						</div>
						<div
							v-if="activeStep == 3"
							key="3"
							class="is-flex is-flex-direction-column is-align-items-center empty-list"
						>
							<div class="is-5">
								<Icon
									class="primary-color"
									file="experiencias/modal-experiencia-ulife"
									size="200px"
								/>
							</div>

							<h3 class="has-text-centered justify-with-image has-text-weight-bold">
								Acesso rápido
							</h3>
							<p class="my-5 has-text-centered justify-with-image has-text-weight-bold">
								Através do Ulife
							</p>

							<p class="modal-desc is-12 has-text-centered">
								Clique no ícone do <span class="has-text-weight-bold">Ulife</span> na home do Portal do Aluno para acessar as experiências online.
							</p>
							<div class="has-text-centered is-flex-tablet modal-btns">
								<b-button
									class="mt-4-mobile modal-btn"
									type="is-primary"
									rounded
									outlined
									@click="prevStep"
								>
									Anterior
								</b-button>
								<b-button
									class="modal-btn"
									type="is-primary"
									rounded
									@click="close"
								>
									Vamos lá
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import Icon from '@components/Icon';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'ModalExperiencia',

	components: {
		Icon,
	},

	data() {
		let model = true;
		let steps = [0, 1, 2, 3];
		let activeStep = 0;

		return {
			steps,
			activeStep,
			model,
		};
	},

	computed: {
		...mapGetters('experiencias', [
			'isModalVisualizado',
		]),
	},

	methods: {
		...mapActions('experiencias', [
			'setVisualizouModalExperienciaAction',
		]),
		async close() {

			if (!this.isModalVisualizado) {
				this.setVisualizouModalExperienciaAction()
			}

			this.$emit('close');
		},
		nextStep() {
			if (this.activeStep < this.steps.length - 1) {
				this.activeStep++;
			}
		},
		prevStep() {
			if (this.activeStep > 0) {
				this.activeStep--;
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '@styles/_variables';

::v-deep {
	.modal-content {
		max-width: 638px !important;
		width: calc(100% - 20px);

		.card {
			border-radius: 1.5rem;
		}
		.card-content {
			padding: 2rem;

			.text-info {
				font-size: 1.125rem
			}
		}
	}
}

.modal-desc {
	display: inline-block;
	min-height: 4.5rem;
}

.modal-btn {
	margin: 0 1rem;
	min-width: 13.75rem;
	padding: 1rem !important;
}

.modal-btns {
  margin-top: 2rem;
}

.steps {
  margin-bottom: 3rem;
}

.step {
  background-color: lightgrey;
  color: white;
  padding: 3px 25px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin: 0 5px 0 5px;
  max-width: 110px;
  max-height: 8px;
}

.step.active {
  background-color: var(--primary);
}

h3 {
	font-size: 1.75rem;
	color: #26496C;

}

h5 {
	color: #173048
}

p {
	color: #173048
}

@media (max-width: 768px) {
  .modal-btns {
		display: flex;
    flex-direction: column-reverse;
  }
}
</style>
