<template>
	<StatusExperienciaUlife
		v-if="statusInterno === StatusEnum.MATRICULADO"
		:experiencia="experiencia"
	/>

	<StatusExperienciaInfo
		v-else
		:experiencia="experiencia"
		:tipo="tipo"
		:status="status"
		:status-interno="statusInterno"
	/>
</template>

<script>
import { ExperienciaTipoEnum } from '@enum/experiencias/experiencia';
import { InteresseStatusEnum } from '@enum/experiencias/interesse';
import { VinculoStatusEnum } from '@enum/experiencias/vinculo';
import StatusExperienciaInfo from '@components/experiencias/StatusExperienciaInfo';
import StatusExperienciaUlife from '@components/experiencias/StatusExperienciaUlife';

const StatusEnum = Object.freeze({
	AGUARDANDO: 'aguardando',
	NAO_CONTEMPLADO: 'nao_contemplado',
	MATRICULADO: 'matriculado',
	CONCLUIDO: 'concluido',
	REPROVADO: 'reprovado',
});

const StatusInteresseMap = Object.freeze({
	[InteresseStatusEnum.AGUARDANDO]: StatusEnum.AGUARDANDO,
	[InteresseStatusEnum.REPROVADO]: StatusEnum.NAO_CONTEMPLADO,
});

const StatusVinculoMap = Object.freeze({
	[VinculoStatusEnum.MATRICULADO]: StatusEnum.MATRICULADO,
	[VinculoStatusEnum.APROVADO]: StatusEnum.CONCLUIDO,
	[VinculoStatusEnum.REPROVADO]: StatusEnum.REPROVADO,
});

export default {
	name: 'StatusExperiencia',

	components: {
		StatusExperienciaInfo,
		StatusExperienciaUlife,
	},

	props: {
		experiencia: {
			required: true,
			type: Object,
		},
	},

	data() {
		return {
			StatusEnum,
		};
	},

	computed: {
		tipo() {
			return this.experiencia.tipo;
		},

		status() {
			if (this.tipo === ExperienciaTipoEnum.INTERESSE) {
				return this.experiencia.interesse.status;
			}

			return this.experiencia.vinculo.status;
		},

		statusInterno() {
			if (this.tipo === ExperienciaTipoEnum.INTERESSE) {
				return StatusInteresseMap[this.status];
			}

			return StatusVinculoMap[this.status];
		},
	},
}
</script>
